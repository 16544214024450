<template>
    <nav class="flex items-stretch rounded overflow-hidden">
        <RouterLink
            v-for="route in routes" :key="route.name"
            :to="route.path"
            class="w-full text-center bg-white text-hd-black-rose p-2 text-xs md:text-sm">
            {{ route.name }}
        </RouterLink>
    </nav>
</template>

<script lang="ts">
export default {
    data() {
        return {
            routes: [
                { path: '/learning/courses', name: 'Courses' },
                { path: '/learning/recipes', name: 'Product Knowledge' },
            ]
        }
    },
    computed: {
        active() { return this.$route.path }
    }
}
</script>

<style scoped>
.router-link-active,
.router-link-active:link,
.router-link-active:visited {
    @apply bg-hd-black-rose text-white;
}
</style>