<template>
    <div>
        <Header :title="`${group?.Title}`" :back-link="`/learning/recipes/${route.params.groupId}`"></Header>
        <div class="grid grid-cols-1 sm:grid-cols-12 sm:gap-y-8 sm:gap-x-4 md:gap-x-12  px-4">
            <div class="col-span-12 text-center">
                <h1 v-if="recipe?.Name1" class="font-hd-serif-italic mt-0 text-hd-gold">{{ recipe?.Name1 }}</h1>
            </div>
            <div class="col-span-1 sm:col-span-6 md:col-span-4 text-center">
                <img :src="`/api/recipe/image/${instance?.Identifier}`" class="w-full max-w-40 sm:max-w-[200px]">
            </div>
            <div v-if="recipe?.Instances" class="col-span-12 sm:col-span-6 md:col-span-7">
                <fieldset>
                    <legend class="h4 border-b">Select a size</legend>
                    <RadioGroup v-model="instance" class="flex gap-4 mb-4">
                        <RadioGroupOption as="template" v-for="option in recipe?.Instances" :key="option?.RecipeInstanceId" :value="option" :aria-label="`${option?.JsonData?.size} ${option?.JsonData?.units}`" v-slot="{ checked }">
                            <div :class="[checked ? 'border-hd-gold ring-1 ring-hd-gold text-hd-gold-dark shadow-md' : 'border-gray-300', 'relative flex cursor-pointer rounded border bg-white px-4 py-2 shadow-sm outline-offset-4 outline-hd-gold focus:outline-1 focus:outline-solid']">
                                <span class="">
                                    {{ option?.JsonData?.size }} {{ option?.JsonData?.units }}
                                </span>
                            </div>
                        </RadioGroupOption>
                    </RadioGroup>
                </fieldset>
                <h3 class="h4 border-b">Instructions</h3>
                <ol class="text-md mt-6">
                    <li v-for="(step, index) in normalizedSteps" :key="index">
                        <p v-for="(line, lineIndex) in step" :key="lineIndex">
                            <span
                                v-for="(part, partIndex) in line"
                                :key="partIndex"
                                :class="[ partIndex % 2 ? 'text-hd-caramel': 'text-hd-black-rose' ]"
                                class="inline-block ml-1">
                                {{ part }}
                            </span>
                        </p>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, defineProps, type PropType } from 'vue';
import { useRoute } from 'vue-router';
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
import {  } from '@heroicons/vue/24/outline';
import { type ParsedRecipe, type ParsedGroup } from '@/stores/learning';
import Header from '@/components/Header.vue';

const route = useRoute();
const props = defineProps({
    recipe: { type: Object as PropType<ParsedRecipe>, required: true },
    group: { type: Object as PropType<ParsedGroup>, required: true },
});

const instance = ref(props.recipe?.Instances?.length && props.recipe?.Instances[0]);
const normalizedSteps = computed(getNormalizedSteps);

type StepLinePart = string[];
type StepLine = StepLinePart[];
type NormalizedSteps = StepLine[];

function getNormalizedSteps():NormalizedSteps|undefined {
    // Step data is organized as an Array of Arrays and has a few considerations based on the Xamarin display:
    // 1. The sub-Array is a single line but the second item is a highlighted in gold.
    // 2. Top-level Array items can be in the same step as the previous one.
    // 3. A next step is indicated by a blank top-level Array item [].
    //
    // This makes displaying an numbered step tricky because, the Xamarin app just displayed a divider line for [].
    // For accessability and easy visual scanning, this logic transforms the data in to:
    // 
    //     Top-level Array Steps >
    //         2nd-level Array Lines (within a step) >
    //             3rd-level Array of Strings containing Parts (of a line).
    //
    return instance.value?.JsonData.steps
        ?.reduce((steps, currentValue) => {
            if(currentValue.length === 0) {
                steps.push([]); // Add a new latest step for future iterations to append to.
                return steps; // Move on without adding the blank array to the step list.
            }
            steps[steps.length - 1].push(currentValue); // Continue adding the line to the last step.
            return steps;
        }, [[]] as NormalizedSteps)
        .filter(step => step.length !== 0); // Clear out the last blank step if it ended with a [].
}
</script>

<style scoped>
 ol {
   list-style: none;
   counter-reset: item;
   margin-left: 0;
 }
 li {
   list-style: none;
   counter-increment: item;
   position: relative;
   padding-left: 30px;
 }
 li:before {
   content: counter(item);
   border-radius: 100%;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   top: -2px;
   left: 0;
   width: 25px;
   height: 25px;
   font-size: 12px;
   line-height: normal;

   @apply bg-hd-black-rose text-white;
 }
</style>