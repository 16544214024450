<template>
    <Modal :open="true" @close="$emit('close')">
        <div v-if="!videoWatched" class="w-[80vw] sm:max-h-[80vh] sm:w-[70vw] sm:max-h-[70vh] aspect-[4/3]">
                <VimeoEmbed
                     v-if="vimeoId"
                    :video-id="vimeoId"
                    :title="title"
                    @ended="onEnded"/>
            <p v-else>
                Unable to play video <RouterLink :to="`${$route.path}/${courseId}/quiz`">start quiz</RouterLink>.
            </p>
        </div>
        <div v-else class="text-hd-black-rose max-w-sm">
            <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-hd-gold">
                <LightBulbIcon class="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <div class="mt-3 sm:mt-5 px-4">
                <h3 v-if="ideas && (ideas?.length||0 > 0)" class="text-center">Key Ideas</h3>
                <h3 v-else class="text-center">Ready to test your knowledge?</h3>
                <ol class="mt-2 mb-6">
                    <li v-for="idea in ideasTransformed" :key="idea.text">
                        {{ idea.text }}
                        <div class="grid grid-cols-1 sm:grid-cols-2 text-hd-caramel gap-2">
                            <div v-for="(column, index) in idea.columns" :key="column">
                                <div>{{ column }}</div>
                                <div v-for="row in idea.rows" :key="row[0]" class="font-hd-sans-book">
                                    {{ row[index] }}
                                </div>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
            <div class="mt-5  text-center">
                <a href="" @click.prevent="videoWatched = false" class="button-primary-outline">
                    <ArrowPathIcon class="w-4 h-4 mr-2"/> Replay video 
                </a>
                <RouterLink :to="`/learning/courses/${courseId}/quiz`" class="button-primary">
                    <DocumentCheckIcon class="w-4 h-4 mr-2"/> Take the quiz 
                </RouterLink>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts" setup>
import { ref, computed, defineProps, defineEmits, type PropType } from 'vue';
import VimeoEmbed from '@/components/VimeoEmbed.vue';
import { LightBulbIcon, ArrowPathIcon, DocumentCheckIcon } from '@heroicons/vue/24/outline';
import { DialogTitle } from '@headlessui/vue';
import Modal from '@/components/Modal.vue';
import { useLearningStore, type CourseGrid } from '@/stores/learning';

const props = defineProps({
    'identifier': { type: String, required: true, },
    'courseId': Number,
    'title': String,
    'ideas': { type: Array as PropType<(string|CourseGrid)[]> },
});

const videoWatched = ref(false);
const vimeoId = computed(getVimeoId);
const ideasTransformed = computed(getIdeasTransformed);

defineEmits([
    'close'
]);

function onEnded() {
    videoWatched.value = true;
}

function getVimeoId() {
    const learningStore = useLearningStore();
    return learningStore.videoMapping[props.identifier];
}

interface NormalizedIdea {
    text?: string,
    columns?: string[],
    rows?: string[][],
}

function getIdeasTransformed() {
    return props.ideas
        // The array contains strings, and/or objects, convert all to objects for easier manipulation.
        ?.map((idea):NormalizedIdea => typeof idea === 'string' ? { text: idea } : idea)
        // Not all ideas display as list items, need to modify the array items
        ?.reduce( (accumulator, currentValue, index, array) => {
            const nextValue = array[index+1];
            // Idea entries with columns/rows should not display as a list item, skip them.
            if(currentValue?.columns) return accumulator;
            if(nextValue?.columns) {
                // Idea entries with columns/rows should be merged with the previous idea list item.
                // This is easier looking at the NEXT item while we have the previous one available in the loop.
                accumulator.push({ ...nextValue, ...currentValue });
            }else {
                // Idea entries with just a text string are kept as-is
                accumulator.push(currentValue);
            }

            return accumulator;
        }, [] as NormalizedIdea[] );
}
</script>

<style scoped>
[disabled="true"] {
    opacity: .5;
    pointer-events: none;
}
</style>