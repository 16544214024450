<template>
    <div class="pb-6">
        <div v-if="quiz">
            <Header :title="quiz?.title" back-link="/learning/courses/"></Header>
            <div v-for="(questionLine, index) in question" :key="questionLine" class="mb-4">
                <h3 v-if="index === 0">{{ questionLine }}</h3>
                <p v-else>{{ questionLine }}</p>
            </div>
            <fieldset aria-label="Privacy setting" class="mb-6">
                <RadioGroup v-model="selected" class="-space-y-px rounded bg-white">
                    <RadioGroupOption v-for="(answer, settingIdx) in quiz?.answers" :key="answer.text" :value="answer"
                        :aria-label="answer.text" :disabled="submitted" as="template" v-slot="{ checked }">
                        <div class="relative flex cursor-pointer border p-4 focus:outline-none items-center" :class="{
                            'rounded-tl rounded-tr': settingIdx === 0,
                            'rounded-bl rounded-br': settingIdx === (quiz?.answers?.length || 0) - 1,
                            'border-gray-300': !checked,
                            'z-10 border-indigo-500 bg-indigo-50 text-indigo-800': checked && !submitted,
                            'z-20 border-green-500 bg-green-50 text-green-800': submitted && answer.correct,
                            'z-10 border-red-500 bg-red-50 text-red-800': checked && submitted && !answer.correct
                        }">
                            <span
                                class="h-4 w-4 rounded-full border flex items-center justify-center shrink-0"
                                :class="{
                                    'border-transparent': checked,
                                    'bg-white border-gray-500': !checked,
                                    'bg-indigo-800': checked && !submitted,
                                    'bg-green-800': checked && submitted && answer.correct,
                                    'bg-red-800': checked && submitted && !answer.correct,
                                }"
                                aria-hidden="true">
                                <span class="rounded-full bg-white w-1.5 h-1.5" />
                            </span>
                            <span class="ml-3 flex flex-col">{{ answer.text }}</span>
                        </div>
                    </RadioGroupOption>
                </RadioGroup>
            </fieldset>
            <button v-if="!submitted" class="ml-0 button-primary" @click.prevent="onSubmit" :disabled="!selected.text">
                Submit
                <ClipboardDocumentListIcon v-if="!isSending" class="w-4 h-4 ml-2" />
                <LoadingSpinner v-else class="w-4 h-4 ml-2 text-hd-caramel" spinner-color-class="text-white" />
            </button>
            <p v-if="submitted && hasError" class="error-text">An error ocurred saving your progress. Please try again or contact your manager.</p>
            <button v-if="submitted && !hasError" class="button-primary" @click.prevent="onNext">
                Next Question
                <ChevronRightIcon class="w-4 h-4 ml-2" />
            </button>
        </div>
        <div v-if="!quiz" class="text-center">
            <CourseComplete
                :title="step?.DataModel?.title || 'Course complete!'"
                :note="step?.DataModel?.note || 'Ready for the next one?'"/>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, defineProps, type PropType } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { RadioGroup, RadioGroupOption } from '@headlessui/vue';
import { ChevronRightIcon, ClipboardDocumentListIcon } from '@heroicons/vue/24/outline';
import CourseComplete from './components/CourseComplete.vue';
import Header from '@/components/Header.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

import { type ParsedSyncData, type QuizAnswers } from '@/stores/learning';

const route = useRoute();

const props = defineProps({
    learningData: Object as PropType<ParsedSyncData>,
});
const currentStep = ref(0);
const currentStepQuiz = ref(0);
const selected = ref({} as QuizAnswers);
const isSending = ref(false);
const hasError = ref(false);
const submitted = ref(false);

const course = computed(getCourseFn);
const step = computed(getStep);
const quiz = computed(getQuiz);
const question = computed(getQuestion);

function getCourseFn() {
    return props.learningData?.Courses.find(course =>
        course.CourseId.toString() === route.params.id
    );
}

function getStep() {
    return course.value?.Steps && course.value?.Steps[currentStep.value];
}

function getQuiz() {
    return step.value?.DataModel?.quizzes && step.value?.DataModel?.quizzes[currentStepQuiz.value];
}

function getQuestion() {
    return quiz.value?.question;
}

async function onSubmit() {
    const stepId = step.value?.CourseStepId;
    hasError.value = false;
    isSending.value = true;
    try {
        const response = await fetch(`/api/course/quizcomplete`, {
            method: 'POST',
            body: JSON.stringify({
                "courseStepId": stepId?.toString(),
                "status": selected.value?.correct ? "2" : "3", // See CourseStepStatus: 0=not viewed, 1=viewed, 2=correct, 3=incorrect
            }),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        });
        if(!response.ok) throw new Error();
    } catch(error) {
        hasError.value = true;
    }
    submitted.value = true;
    isSending.value = false;
}

function onNext() {
    submitted.value = false;
    selected.value = {} as QuizAnswers;

    const stepHasMoreQuizzes = (step.value?.DataModel?.quizzes?.length || 0) > (currentStepQuiz.value + 1);
    if (stepHasMoreQuizzes) {
        currentStepQuiz.value += 1;
        return;
    }

    const hasNextStep = (course.value?.Steps?.length || 0) > (currentStep.value + 1);
    if (hasNextStep) {
        currentStepQuiz.value = 0;
        currentStep.value += 1;
        return;
    }

    // No next step, display default end screen.
    currentStepQuiz.value = 0;
    currentStep.value += 1; // intentionally invalid step without quiz data
}
</script>

<script lang="ts">
</script>

<style scoped></style>