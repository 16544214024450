<template>
   <div>
        <!-- See Xamarin repo, HDScoops/HDScoops/Views/HDRecipePopupView.xaml.cs for logic -->
        <FlavorFacts v-if="group?.Title === 'Flavor Facts'" :group />
        <!-- See Xamarin repo, HDScoops/HDScoops/Models/HDRecipeGroup.cs public bool IsBasicGroup for logic -->
        <Basics v-else-if="group?.Title === 'basics'" :recipe :group />
        <!-- See Xamarin repo, HDScoops/HDScoops/Models/HDRecipeGroup.cs public bool IsRewardsGroup for logic -->
        <Rewards v-else-if="group?.Title === 'sweet rewards'" :recipe :group />
        <StandardRecipe v-else :recipe :group />
    </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, type PropType } from 'vue';
import { useRoute } from 'vue-router';
import { type ParsedSyncData } from '@/stores/learning';
import StandardRecipe from './components/StandardRecipe.vue';
import Rewards from './components/Rewards.vue';
import FlavorFacts from './components/FlavorFacts.vue';
import Basics from './components/Basics.vue';

const props = defineProps({
    learningData: Object as PropType<ParsedSyncData>,
});

const route = useRoute();
const group = computed(getGroup);
const recipe = computed(getRecipe);

function getGroup() {
    return props.learningData?.Groups.find(group =>
        group.RecipeGroupId.toString() === route.params.groupId
    );
}

function getRecipe() {
    return group.value?.Recipes?.find(recipe =>
        recipe.RecipeId.toString() === route.params.recipeId
    );
}

</script>

<style scoped>
</style>