<template>
    <div>
        <Header :title="`${group?.Title}`" :back-link="`/learning/recipes/${group.RecipeGroupId}`"></Header>
        <div class="grid grid-cols-1 sm:grid-cols-12 gap-y-8 sm:gap-x-4 md:gap-x-12 px-4 sm:px-0">
            <div class="col-span-12 text-center">
                <h1 class="font-hd-serif-italic mt-0 text-hd-gold">{{ flavor.name }}</h1>
            </div>
            <div class="col-span-12 sm:col-span-4 md:col-span-4 text-center sm:text-center md:text-right">
                <img :src="flavor.image" class="w-full max-w-40 sm:max-w-[150px] sm:my-8">
            </div>
            <div class="col-span-12 sm:col-span-8 md:col-span-6 text-center sm:text-left sm:mt-4">
                <p v-if="flavor.description" class="text-xl mb-12">{{ flavor?.description }}</p>
                <div v-if="flavor.isRbstFree" class="mb-6">
                    <h2 class="h4 border-b text-hd-caramel">No rBST</h2>
                    <p>Made with milk & cream from cows that are not treated with the growth hormone rBST.</p>
                </div>
                <div v-if="flavor.isKosher" class="mb-6">
                    <h2 class="h4 border-b text-hd-caramel">Certified Kosher</h2>
                    <p>Manufactured Certified Kosher.</p>
                </div>
                <div v-if="flavor.isGmoFree" class="mb-6">
                    <h2 class="h4 border-b text-hd-caramel">No GMO</h2>
                    <p>Flavor does not contain GMO ingredients.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, ref, type PropType } from 'vue';
import { type ParsedRecipe, type ParsedGroup, useLearningStore } from '@/stores/learning';
import Header from '@/components/Header.vue';
import { useRoute } from 'vue-router';

const route = useRoute();
defineProps({
    group: { type: Object as PropType<ParsedGroup>, required: true },
});

const flavor = computed(getFlavor);

function getFlavor() {
    const learningStore = useLearningStore();
    return learningStore.flavorFacts[route.params.recipeId as string]
}
</script>

<style scoped>
</style>