<template>
    <div style="height: 100%;">
        <iframe ref="videoIframe"
            :src="`https://player.vimeo.com/video/${videoId}?badge=0&amp;autopause=0&autoplay=1&amp;player_id=0&amp;playsinline=1&amp;app_id=58479`"
            frameborder="0"
            allow="autoplay; picture-in-picture; clipboard-write"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            :title="title"></iframe>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import Player from '@vimeo/player';

const videoIframe = ref();
const emit = defineEmits(['ended']);
defineProps(['videoId', 'title']);

onMounted(()=> {
    if(!videoIframe?.value) return;
    const player = new Player(videoIframe?.value);
    player.on('ended', () => {
        emit('ended')
    });
})
</script>

<style scoped></style>