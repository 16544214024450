import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import './push-notifications'

import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(createPinia())
app.use(router);

const element = document.getElementById('app');
router.push('/learning/courses');
if(element) {
    app.mount('#app');
}

export function resetVueRoute(route='/') {
    router.push(route);
}
globalThis.resetVueRoute = resetVueRoute;
