<template>
    <div>
        <Header :title="`${group?.Title}`" :back-link="`/learning/recipes/`"></Header>
        <div class="grid grid-cols-1 sm:grid-cols-12 gap-y-8 sm:gap-x-4 md:gap-x-12">
            <div class="col-span-12 text-left">
                <h1 v-if="recipe?.Name2" class="font-hd-serif-italic mt-0 mb-0 text-hd-gold">{{ recipe?.Name2 }}</h1>
            </div>
            <div class="col-span-12 sm:col-span-6 md:col-span-8 sm:text-lg">
                <ul class="text-left">
                    <li v-for="(reward, index) in instance?.JsonData?.rewards" :key="reward">
                        <span class="text-hd-caramel" v-if="instance?.JsonData?.points">{{ instance?.JsonData?.points[index] }}</span>
                        <span class="ml-1">{{ reward }}</span>
                    </li>
                </ul>
            </div>
            <div class="xshidden col-span-12 sm:col-span-6 md:col-span-4 text-center">
                <img :src="`/api/recipe/image/${instance.Identifier}`" class="w-full max-w-40 sm:max-w-[150px]">
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, type PropType } from 'vue';
import { type ParsedRecipe, type ParsedGroup } from '@/stores/learning';
import Header from '@/components/Header.vue';

const props = defineProps({
    recipe: { type: Object as PropType<ParsedRecipe>, required: true },
    group: { type: Object as PropType<ParsedGroup>, required: true },
});

const instance = ref(props.recipe.Instances[0])
</script>

<style scoped>
ul, li {
    margin-left: 0;
    list-style: none;
}
</style>