<template>
   <div class="flex flex-col w-full max-h-full">
        <slot name="header">
            <header class="py-2 md:pb-4 md:pt-0 shadow -mx-2 px-2 md:-mx-4 md:px-4 bg-hd-old-cream md:bg-transparent relative">
                <div class="mx-auto max-w-5xl px-2 md:px-4">
                    <LearningNavigation/>
                </div>
            </header>
        </slot>
        <main id="vue-main-scroll" class="flex h-full overflow-y-auto pt-2 md:pt-4 bg-white px-2 md:px-4">
            <div class="max-w-5xl mx-auto w-full">
                <RouterView v-if="!isLoading" :learning-data="learningData" />
                <div v-else class="min-h-[200px] grid w-full place-items-center">
                    <LoadingSpinner/>
                </div>
            </div>
        </main>
   </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useLearningStore, type ParsedSyncData } from '@/stores/learning';
import LearningNavigation from './components/LearningNavigation.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { storeToRefs } from 'pinia';

const learningStore = useLearningStore();
const isLoading = ref(true);
// const learningData = ref(null as ParsedSyncData | null);
const { learningData } = storeToRefs(learningStore);

onMounted(async ()=>{
    await learningStore?.getLearningData();
    isLoading.value = false;
//     learningData.value = learningStore?.learningData;
})
</script>

<style scoped>
</style>