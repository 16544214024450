<template>
    <ThumbnailGrid :items="groups" />
</template>

<script lang="ts" setup>
import { computed, defineProps, type PropType } from 'vue';
import { type ParsedSyncData, type ParsedGroup } from '@/stores/learning';
import ThumbnailGrid from '@/components/ThumbnailGrid.vue';

const props = defineProps({
    learningData: Object as PropType<ParsedSyncData>,
});

const groups = computed(getGroups);

function getGroups() {
    return props.learningData?.Groups
        .map(group => ({
            imageSrc: getGroupThumbnail(group),
            imageAlt: '',
            key: group.RecipeGroupId,
            title: group.Title,
            link: `/learning/recipes/${group.RecipeGroupId}`,
        }))
}

function getGroupThumbnail(group:ParsedGroup) {
    // For logic, see OTHER repo hdscoops.xamarin for Xamarin apps
    // public ImageSource Image
    // in HDScoops/HDScoops/Models/HDRecipeGroup.cs
    const imageApiBasePath = '/api/recipe/image/';

    if(group.RecipeGroupId === 17) {
        // todo: double check with production data. this is from staging.
        // "sweet rewards" has ThumbnailIndex 0, recipes, and the first instance identifier is a missing image
        // the group.Identifier has an image though, and that's what the apps are using.
        return imageApiBasePath + group.Identifier;
    }

    if((group.Recipes.length > 0) && group.ThumbnailIndex >= 0) {
        if(group.ThumbnailIndex < group.Recipes.length) {
            return imageApiBasePath + group.Recipes[group.ThumbnailIndex].Instances[0].Identifier
        }
        return imageApiBasePath + group.Recipes[0].Instances[0].Identifier;
    }

    return imageApiBasePath + group.Identifier; // iPhone used this for all but was a larger background tile instead of thumbnail.
}
</script>

<style scoped>
</style>