import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const $ = globalThis.$;

const app = initializeApp({
    // PUBLIC INFO
    // https://firebase.google.com/docs/web/learn-more#config-object
    // Note: The Firebase config object contains unique, but non-secret identifiers for your Firebase project.
    // https://firebase.google.com/docs/projects/learn-more#config-files-objects
    apiKey: "AIzaSyDIINiVM5BmuBAA3T4yMF7tMfYSS-PLxZg",
    authDomain: "haagen-dazs-hdscoops.firebaseapp.com",
    databaseURL: "https://haagen-dazs-hdscoops.firebaseio.com",
    projectId: "haagen-dazs-hdscoops",
    storageBucket: "haagen-dazs-hdscoops.appspot.com",
    messagingSenderId: "279228664151",
    appId: "1:279228664151:web:daf31f180773465987d612",
    measurementId: "G-QEEDDFMPXW"
});
const messaging = getMessaging(app);
displayConditionalMessaging();

onMessage(messaging, (payload) => {
    if(!payload?.notification?.title || !payload?.notification?.body ) return;
    const notification = new Notification(payload.notification.title, { body: payload.notification.body });
    notification.onclick = () => {
        notification.close();
        window.parent.focus();
   }
});






function displayConditionalMessaging() {
    
    const isStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if(isSafari && !isStandaloneMode) {
        // Safari requires saving to home screen before asking for push notification permissions.
        return showInstallFromSafariPrompt();
    }else if((navigator.userAgent.toLowerCase().indexOf("android") > -1) && !isStandaloneMode) {
        return showInstallFromAndroidPrompt();
    }

    if(document.getElementById('login')) {
        // Don't register with Firebase or ask the user for notification permissions if they're not logged in.
        return;
    }

    if (!("Notification" in window)) {
        // Push notifications are not supported in this browser.
        return;
    } else if (Notification.permission === "granted") {
        checkNotificationPermissionAndGetToken();
        return;
    } else if (Notification.permission === "denied") {
        return showPermissionDenied();
    }

    // Permission prompt needs to come from a user gesture.
    // Otherwise desktop Safari blocks it without warning, desktop Chrome blocks but briefly displays a small popup.
    // Chrome on Android works but should be consistent.
    showPermissionDescription();

}

function showInstallFromSafariPrompt() {
    if(!$) return;
    $('#install-from-safari-modal')?.modal('show');
    document.body.classList.add("install-from-safari");
}

function showInstallFromAndroidPrompt() {
    if(!$) return;
    $('#install-from-android-modal')?.modal('show');
    document.body.classList.add("install-from-android");
}

function showPermissionDescription() {
    if(!$) return;
    document.body.addEventListener('click', (event) => {
        if(!event.target?.matches('.prompt-for-push-permission, .prompt-for-push-permission *')) {
            return;
        }
        event.preventDefault();
        checkNotificationPermissionAndGetToken();
    });
    $('#request-push-modal')?.modal('show');
    document.querySelector('body')?.classList.add("request-push");
}

function showPermissionDenied() {
    if(!$) return;
    $('#request-push-denied-modal')?.modal('show');
    document.querySelector('body')?.classList.add("request-push-denied");
}

async function checkNotificationPermissionAndGetToken() {
    // PUBLIC INFO
    // https://firebase.google.com/docs/cloud-messaging/js/client#web
    // https://web.dev/articles/push-notifications-web-push-protocol#application_server_keys
    const PUBLIC_vapid_key = 'BOgkbXfJeKXCIcje0i8NEM3hTttcZw0Uvr-rOF-JE29C-DJII1OAgb-spuFkGXhiws8JmKCJTuYA6OniVoqqXT4';

    try {
        console.info('getting token');
        return getToken(messaging, {vapidKey: PUBLIC_vapid_key})
            .then(async (currentToken) => {
                if (currentToken) {
                    console.log(`Token: ${currentToken}`);
                    try {
                        const response = await fetch(`/api/device/token`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json;charset=UTF-8',
                                'X-HDP-DEVICETOKEN': currentToken
                            }
                        });
                        if(!response.ok) throw new Error();
                    } catch(error) {
                        console.info('Error registering token in database.');
                    }
                    return currentToken;
                } else {
                    console.info('No registration token available. Request permission to generate one.');
                }
            })
            .catch((err) => {
                console.info('An error occurred while retrieving token. ', err);
            });
    }catch(error) {
        console.info('getToken error', error)
    }
}