<template>
   <div class="text-center">
        <h1 class="font-hd-serif-italic text-hd-strawberry">
            {{ title }}
        </h1>
        <p>{{ note }}</p>
        <p v-if="hasError" class="error-text">An error ocurred saving your progress. Please try again or contact your manager.</p>
        <p>
            <img src="@/assets/images/sweetness_icon.png"
                class="inline-block w-10"
                style="filter: invert(75%) sepia(10%) saturate(7433%) hue-rotate(315deg) brightness(109%) contrast(98%);">
        </p>
        <p><RouterLink class="button-primary" to="/learning/courses">View all courses</RouterLink></p>
    </div>
</template>

<script lang="ts" setup>
import { useLearningStore } from '@/stores/learning';
import { defineProps, ref, onMounted } from 'vue';
const props = defineProps({
    title: String,
    note: String,
});
const hasError = ref(false);

onMounted(async () => {
    const learningStore = useLearningStore();
    await learningStore.getLearningData();
})
</script>

<style scoped>
</style>