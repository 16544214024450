<template>
  <div>
    <div class="grid grid-cols-1 w-full sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 md:gap-4 pb-2 md:pb-4">
        <div v-for="(course, $i) in learningData?.Courses" :key="course.CourseId">
            <a href=""
                @click.prevent="selectedCourse = course; showCourseModal = true"
                class="course-title p-4 flex justify-center items-center text-center relative"
                :style="{ backgroundImage: `url(/api/course/image/${course.Identifier})` }">
                <span v-if="(isComplete.length > 0) && isComplete[$i]" class="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-50"></span>
                <span class="font-hd-serif absolute top-0 right-0 p-2 border-b border-white text-base">{{ $i + 1 }}</span>
                <span>
                    <span
                    :class="element.font === 'Bodoni-PosterItalic' ? 'font-hd-serif-italic' : 'font-hd-sans text-xs'"
                    class="text-4xl align-baseline"
                    v-for="element in course.JsonData.title"
                    :key="element.text">
                        {{ element.text.replace('\n', ' ') }}
                    </span>
                </span>
                <span v-if="(isComplete.length > 0) && isComplete[$i]" class="absolute bottom-4 w-12 h-12 rounded-full flex items-center justify-center bg-hd-caramel">
                    <CheckIcon class="w-6 h-6"/>
                </span>
                <span v-else class="absolute bottom-4 w-12 h-12 rounded-full flex items-center justify-center bg-hd-red">
                    <PlayIcon class="w-6 h-6"/>
                </span>
            </a>
        </div>
    </div>
    <CourseVideoModal
        v-if="selectedCourse?.CourseId"
        :course-id="selectedCourse?.CourseId"
        :identifier="selectedCourse?.Identifier"
        :title="selectedCourse?.JsonData?.title?.map(item => item.text)?.join('')?.replace('\n', ' ')"
        :ideas="selectedCourse?.JsonData?.ideas"
        @close="showCourseModal = false; selectedCourse = {} as ParsedCourse;"/>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, computed, type PropType } from 'vue';
import { CheckIcon, PlayIcon } from '@heroicons/vue/24/outline';
import CourseVideoModal from './components/CourseVideoModal.vue';
import { type ParsedSyncData, type ParsedCourse } from '@/stores/learning';

const selectedCourse = ref({} as ParsedCourse);
const showCourseModal = ref(false);
const isComplete = computed(getIsComplete)

const props = defineProps({
    learningData: Object as PropType<ParsedSyncData>,
});

function getIsComplete() {
    return props.learningData?.Courses?.map(course => {
        return course?.Steps
            // Only steps with quizzes count towards progress
            .filter(step => step?.DataModel?.quizzes)
            // Just need the step id to look up if it's complete
            .map(step => step?.CourseStepId)
            // make sure all the step ids are in the progress array
            .every(stepId => props.learningData?.Progress?.find(item =>
                (item?.CourseStepId === stepId)
                && (item?.Status > 1) // See CourseStepStatus: 0=not viewed, 1=viewed, 2=correct, 3=incorrect
            ));
    }) || [];
}
</script>

<style scoped lang="pcss">
.course-title {
    white-space: pre;
    text-wrap: wrap;
    color: #fff;
    background-size: contain;
    line-height: normal;
    min-height: 250px;

    @apply sm:aspect-[4/3] sm:min-h-0;
}
</style>
