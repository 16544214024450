<template>
    <div>
        <Header :title="`${group?.Title}`" :back-link="`/learning/recipes/${group.RecipeGroupId}`"></Header>
        <div class="grid grid-cols-1 sm:grid-cols-12 gap-y-8 sm:gap-x-4 sm:gap-y-8 md:gap-x-12">
            <div class="col-span-12 text-center">
                <h1 v-if="recipe?.Name1" class="font-hd-serif-italic mt-0 text-hd-gold">{{ recipe.Name1 }}</h1>
            </div>
            <div class="col-span-12 sm:col-span-6 md:col-span-6 text-center sm:text-right">
                <img :src="`/api/recipe/image/${instance.Identifier}`" class="w-full max-w-40 sm:max-w-[200px]">
            </div>
            <div class="col-span-12 sm:col-span-6 md:col-span-6 text-center sm:text-left">
                <div v-if="recipe.Name1 === 'cups & cones'">
                    <!-- "cups & cones" column/rows are organized the opposite of "classic sundae" in the Xamarin app -->
                    <div v-for="(column, index) in instance.JsonData.columns" :key="column">
                        <h2>{{ column }}</h2>
                        <ul>
                            <li v-for="(rowLine, rowLineIndex) in instance.JsonData.rows" :key="rowLineIndex">
                                <span
                                    v-for="(part, partIndex) in rowLine[index]"
                                    :key="part"
                                    :class="[ partIndex % 2 ? 'text-hd-caramel' : 'text-hd-black-rose' ]"
                                    class="inline-block mr-1">
                                    {{ part }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-else>
                    <!-- "classic sundae" column/row organization for a table in the Xamarin app -->
                    <div v-for="(size, sizeIndex) in instance.JsonData.rows" :key="sizeIndex">
                        <h2 class="h3">{{ size[0].join(' ').replace('\n', '') }}</h2>
                        <ul>
                            <li v-for="(line, lineIndex) in size.slice(1)" :key="lineIndex">
                                <span
                                    v-for="(part, partIndex) in line"
                                    :key="part"
                                    class="inline-block mr-1 text-hd-black-rose">
                                    {{ part }}
                                </span>
                                <span v-if="instance.JsonData.columns" class="text-hd-caramel">
                                    {{ instance.JsonData.columns[lineIndex+1] }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, type PropType } from 'vue';
import { type ParsedRecipe, type ParsedGroup } from '@/stores/learning';
import Header from '@/components/Header.vue';

const props = defineProps({
    recipe: { type: Object as PropType<ParsedRecipe>, required: true },
    group: { type: Object as PropType<ParsedGroup>, required: true },
});

const instance = ref(props.recipe?.Instances[0]);
</script>

<style scoped>
ul, li {
    margin-left: 0;
    list-style: none;
}
</style>