<template>
    <div>
        <Header :title="group?.Title" back-link="/learning/recipes/"></Header>
        <ThumbnailGrid
            v-if="group?.Title === 'Flavor Facts'"
            :items="flavorFactsGridItems"
            item-container-classes=""
            image-classes="w-28 h-28 md:w-36 md:h-36"/>
        <div v-else class="max-w-[560px] md:max-w-[820px] mx-auto">
            <ThumbnailGrid :items="thumbnailGridItems"/>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, onMounted, type PropType } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useLearningStore, type ParsedSyncData } from '@/stores/learning';
import ThumbnailGrid, { type ThumbnailItem } from '@/components/ThumbnailGrid.vue';
import Header from '@/components/Header.vue';

const route = useRoute();
const router = useRouter();

const props = defineProps({
    learningData: Object as PropType<ParsedSyncData>,
});

const group = computed(getGroup);
const thumbnailGridItems = computed(getThumbnailGridItems);
const flavorFactsGridItems = computed(getFlavorFactsGridItems)

onMounted(() => {
    // See Xamarin repo, HDScoops/HDScoops/Views/HDRecipePopupView.xaml.cs for logic
    const skipRecipeSelection = [
        'sweet rewards'
    ]
    if(skipRecipeSelection.includes(group.value?.Title || '')) {
        router.replace(`/learning/recipes/${route.params.groupId}/${group.value?.Recipes[0]?.RecipeId || 'detail'}`)
    }
});

function getGroup() {
    return props.learningData?.Groups.find(group =>
        group.RecipeGroupId.toString() === route.params.groupId
    );
}

function getThumbnailGridItems() {
    return group.value?.Recipes
        .map(recipe => ({
            imageSrc: `/api/recipe/image/${recipe.Instances[0].Identifier}`,
            imageAlt: '',
            key: recipe.RecipeId,
            title: recipe.Name1,
            link: `/learning/recipes/${route.params.groupId}/${recipe.RecipeId}`,
        }));
}

function getFlavorFactsGridItems():ThumbnailItem[] {
    const learningStore = useLearningStore();
    return Object.entries(learningStore.flavorFacts)
        .map(([key, flavor], index) => ({
            imageSrc: flavor.image,
            imageAlt: '',
            key: key,
            title: flavor.name,
            link: `/learning/recipes/${route.params.groupId}/${key}`,
        }))
}
</script>

<style scoped>
</style>