import { createMemoryHistory, createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Learning from '@/views/learning/Learning.vue'
import CourseListing from '@/views/learning/courses/CourseListing.vue'
import CourseQuiz from '@/views/learning/courses/CourseQuiz.vue'
import GroupListing from '@/views/learning/recipes/GroupListing.vue'
import GroupRecipeListing from '@/views/learning/recipes/GroupRecipeListing.vue'
import RecipeDetail from '@/views/learning/recipes/RecipeDetail.vue'

const router = createRouter({
  history: createMemoryHistory(),//createWebHashHistory(),//createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/learning',
      name: 'learning',
      component: Learning,
      children: [
        {
          path: 'courses',
          children: [
            {
              path: '',
              name: 'allCourses',
              component: CourseListing,
            },
            {
              path: ':id/quiz',
              name: 'courseQuiz',
              component: CourseQuiz
            },
          ]
        },
        {
          path: 'recipes',
          children: [
            {
                path: '',
                name: 'recipeGroups',
                component: GroupListing,
            },
            {
                path: ':groupId',
                name: 'recipes',
                component: GroupRecipeListing,
            },
            {
                path: ':groupId/:recipeId',
                name: 'recipe',
                component: RecipeDetail,
            },
          ]
        }
      ],
    },
  ]
});

router.afterEach((to, from) => {
    const scroller = document.getElementById('vue-main-scroll');
    if(!scroller) return;
    scroller.scrollTop = 0;
    window.scroll(0, 0);
});

export default router
